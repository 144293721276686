export function objectToQueryString(obj: Record<string, any>): string {
  const params = new URLSearchParams();
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      params.append(key, value.join(","));
    } else {
      params.append(key, value);
    }
  });
  return params.toString();
}
