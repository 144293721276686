"use client";

import React, { useState } from "react";
import NextImage, { ImageProps as NextImageProps } from "next/image";

export interface ImageProps extends NextImageProps {
  src: string;
  fallback?: string;
}

export default function Image({ src, fallback, width=100, height=100, ...props }: ImageProps){
  const [imgSrc, setImgSrc] = useState(src);
  return (
    <NextImage
      src={imgSrc}
      width={width}
      height={height}
      {...props}
      alt={""}
      onError={() => {
        fallback && setImgSrc(fallback);
      }}
    />
  );
};

// interface ImageWithFallbackProps extends ImageProps {
//   fallbackSrc: string;
// }

// function ImageWithFallback(props: ImageWithFallbackProps){
//   const { src, fallbackSrc, ...rest } = props;
//   const [imgSrc, setImgSrc] = useState(src);
//
//   return (
//     <Image
//       {...rest}
//       src={imgSrc}
//       alt={""}
//       onError={() => {
//         setImgSrc(fallbackSrc);
//       }}
//     />
//   );
// };
//
// export default ImageWithFallback;
