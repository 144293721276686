'use client';
import {usePathname, useRouter, useSearchParams} from 'next/navigation';
import React, { useRef, useEffect } from 'react'

const PreviousRouteContext = React.createContext<string|undefined>(undefined);

export default function PreviousRouteProvider({children}: {children: React.ReactNode}) {
  const previousRoute = useRef<undefined|string>(undefined);
  const path = usePathname();
  const searchParams = useSearchParams().toString();

  useEffect(() => {
    previousRoute.current = `${path}${searchParams ? `?${searchParams}` : ''}`;
  }, [path, searchParams]);

  return <PreviousRouteContext.Provider value={previousRoute.current}>
    {children}
  </PreviousRouteContext.Provider>
}

export function usePreviousRoute() {
  return React.useContext(PreviousRouteContext);
}
