'use client'

import { useRouter } from "next/navigation"

import IconChevronLeft from "@/assets/icon/chevron-left.svg";
import {usePreviousRoute} from "@/app/pwa/lib/previous-route-context";

export default function SimpleLayoutPageBackButton({className, fallbackUrl} : {className?:string, fallbackUrl?:string}) {
  const router = useRouter()
  const prevRoute = usePreviousRoute()

  function handleBackClick(){
    // TODO:Timo! why is prevRoute always undefined?
    // console.log("prevRoute: ", prevRoute)
    // console.log("document.referrer: ", document.referrer)
    // console.log("window.location.href: ", window.location.href)
    if(prevRoute){
      // console.log("go prevRoute")
      router.back();
    } else if(document.referrer && document.referrer.startsWith(window.location.origin) && document.referrer != window.location.href) {
      // console.log("go document.referrer")
      router.push(document.referrer);
    } else {
      // console.log("go fallback")
      router.push(fallbackUrl || "/");
    }
  }
 
  return (
    <button type="button" className={`button text-white-forced ${className || ""}`} onClick={handleBackClick}>
      <IconChevronLeft className="button__icon--left" />Zurück
    </button>
  )
}
