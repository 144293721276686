'use client'

import { useRouter } from "next/navigation"
import IconChevronLeft from "@/assets/icon/chevron-left.svg";
import {usePreviousRoute} from "@/app/pwa/lib/previous-route-context";

export default function NavBackButton({on, className, fallbackUrl} : {on?: "primary" | "light", className?:string, fallbackUrl?: string}) {
  const router = useRouter()
  const prevRoute = usePreviousRoute()

  function handleBackClick(){
    if(prevRoute || document.referrer && document.referrer.startsWith(window.location.origin)){
      router.back();
    } else {
      router.push(fallbackUrl || "/");
    }
  }

  return (
    <button type="button" className={`mobile-nav-button ${on === "primary" ? "mobile-nav-button--on-primary" : ""} ${className || ""}`} onClick={handleBackClick}>
      <IconChevronLeft />
    </button>
  )
}
