import { Contest, Team } from "@/sources/kicker-amateur/types";
import { Inquiry } from "@vereinsheim/backend-types/src/inquiry";
import {
  EventListQuery
} from "@vereinsheim/backend-types/src/event";
import{PollListQuery} from "@vereinsheim/backend-types/src/poll"

import {
  PushNotificationListQuery
} from "@vereinsheim/backend-types/src/push";


import * as process from "process";
import {objectToQueryString} from "@/utils/queryParamsHelper";

export function isProduction() {
  return process.env.BASE_URL == "https://www.kicker-vereinsheim.de" ? true : false;
}

export function kickerTippspielJs() {
  return isProduction() ? "https://www.kicker-tippspiel.de/entry/index.js" : "https://www.staging.kicker-tippspiel.de/entry/index.js";
}

export function kickerGrafikgeneratorUrl(clubId: string, page: string, token: any) {
  const params = new URLSearchParams({ club: clubId, token });
  return isProduction() ? `https://www.grafikgenerator.app/vereinsheim/${page}?${params.toString()}` : `https://dev.grafikgenerator.de/vereinsheim/${page}?${params.toString()}`;
}

export function kickerCmsUrl(clubId: string) {
  return `http://cms.dora.kicker-amateurfussball.de/live/Verein?id=${clubId}`;
}

function baseUrl(subdomain?: string) {
  if (!process.env.BASE_URL) {
    throw "Missing BASE_URL Environment Variable";
  }

  if (subdomain) {
    return process.env.BASE_URL.replace("www", subdomain);
  } else {
    return process.env.BASE_URL;
  }
}

export function wwwHomeUrl() {
  return baseUrl();
}

export function pwaAdminInquiryEditUrl(inquiry: Inquiry) {
  return `${pwaHomeUrl(inquiry.appId)}/admin/inquiry/${inquiry.id}/edit`;
}

export function pwaAdminInquiryGetUrl(clubId: string, inquiryId: string) {
  return `${pwaHomeUrl(clubId)}/api/inquiry/${inquiryId}`;
}

export function pwaHomeUrl(clubId: string) {
  return baseUrl(`club${clubId}`);
}

export function pwaHomePath() {
  return "/";
}

export function pwaClubIconUrl(clubId: number, width: number, background: "transparent" | "white", padding: boolean) {
  return `${baseUrl(`club${clubId}`)}/api/image/club-icon/${clubId}/${width}/${background}/${padding}`;
}

export function pwaInvitationConfirmUrl(origin: string, token: string) {
  //return `${origin}/einladung/${token}/bestätigen`
  return `${origin}/invitation/${token}/confirmation`
}

export function pwaTeamPlayerPath(
  teamId: string | number,
  playerId: string | number
) {
  return `/team/${teamId}/player/${playerId}`;
}

export function pwaTeamPlayerEditPath(teamId: string | number, playerId: string | number) {
  return `/team/${teamId}/player/${playerId}/edit`;
}

export function pwaTeamMemberCreatePath(teamId: string | number, functionId?:string | number ) {
  let path = `/team/${teamId}/player/new/create`
  return path + (functionId ? `?functionId=${functionId}`:"")
}

export function pwaTeamPlayerTransferFunctionPath(teamId: string | number, functionId: string | number | null) {
  if(functionId) {
    return `/team/${teamId}/player/new/${functionId}/transfer`;
  }
  else {
    return `/team/${teamId}/player/new/25/transfer`;
  }
}

export function pwaTippspielPath(clubId: string) {
  return `/tippspiel/vereinsheim/ama${clubId}`;
}

export function pwaGrafikgeneratorPath(clubId: string) {
  return `/grafikgenerator`;
}

export function pwaGrafikgeneratorPagePath(clubId: string, page: string) {
  return `/grafikgenerator/${page}`;
}

export function pwaTeamPath(teamId: string) {
  return `/team/${teamId}`;
}

export function pwaTeamEventsPath(teamId: string, query?: Record<string, string>) {
  return path(`/team/${teamId}/termine`, query);
}

export function pwaTeamPollsPath(teamId: string, query?: Record<string, string>) {
  return  path(`/team/${teamId}/umfragen`, query);
}

export function pwaTeamGamePath(teamId: string, gameId: string, query?: Record<string, string>) {
  return path(`/team/${teamId}/game/${gameId}`, query);
}

export function pwaPollsPath(query?: Record<string, string>) {
  return path(`/umfragen`, query);
}

export function pwaAllNewsPath() {
  return path(`/neuigkeiten`);
}

export function pwaPollCreatePath(query?: Record<string, string>){
  return path(`/umfrage/erstellen`,query);
}

export function pwaTeamPollCreatePath(teamId: string,query?: Record<string, string>) {
  return path(`/team/${teamId}/umfrage/erstellen`,query);
}

export function pwaPollEditPath(id: string, query?: Record<string, string>) {
  return path(`/umfrage/${id}/bearbeiten`, query);
}

export function pwaTeamCurrentGamedayPath(teamId: string) {
  return `/team/${teamId}/gameday`;
}

export function pwaTeamGamedayPath(teamId: string, gamedayId?: string) {
  if(gamedayId){
    return `/team/${teamId}/gameday/${gamedayId}`;
  }
  return `/team/${teamId}/gameday`;
}

export function pwaTeamTablePath(teamId: string) {
  return `/team/${teamId}/table`;
}

export function pwaTeamSchedulePath(teamId: string) {
  return `/team/${teamId}/schedule`;
}

export function pwaTeamSquadPath(teamId: string, refresh?: boolean) {
  if(refresh) {
    return `/team/${teamId}/kader?refresh=true`;
  }
  else {
    return `/team/${teamId}/kader`;
  }
}


export function pwaTeamTabPath(teamId: string, teamTabId:string) {
  return `/team/${teamId}/team-tab/${teamTabId}`;
}

export function pwaTeamTabsSettings(teamId: string) {
  return `/team/${teamId}/einstellungen`;
}
export function pwaTeamTabAddPath(teamId: string) {
  return `/team/${teamId}/team-tab/erstellen`;
}

export function pwaTeamTabEditPath(teamId: string, tabId:string) {
  return `/team/${teamId}/team-tab/${tabId}/bearbeiten`;
}

export function pwaTeamWidgetsPath(teamId: string) {
  return `/team/${teamId}/widgets`;
}

export function pwaNewsClubCreatePath() {
  return `/news/erstellen`;
}

export function pwaNewsClubListPath() {
  return `/news/`;
}

export function pwaNewsClubPath(id: string) {
  return `/news/${id}`;
}

export function pwaNewsClubEditPath(newsId:string) {
  return `/news/${newsId}/bearbeiten`;
}

export function pwaNewsTeamCreatePath(teamId:string) {
  return `/team/${teamId}/news/erstellen`;
}

export function pwaNewsTeamListPath(teamId:string) {
  return `/team/${teamId}/news/`;
}

export function pwaNewsTeamPath(id: string, teamId: string) {
  return `/team/${teamId}/news/${id}`;
}

export function pwaNewsTeamEditPath(teamId:string, newsId:string) {
  return `/team/${teamId}/news/${newsId}/bearbeiten`;
}

export function pwaOvNewsTeamPath(id: string, teamId: string, title: string) {
  return `/team/${teamId}/kicker-news/${id}/${title}`;
}

export function pwaOvNewsPath(id: string, title: string) {
  return `/kicker-news/${id}/${title}`;
}

export function pwaOvNewsTeamListPath(teamId: string) {
  return `/team/${teamId}/kicker-news`;
}

export function pwaOvNewsListPath() {
  return `/kicker-news`;
}

export function pwaSocialMediaListPath() {
  return `/social-media`;
}

export function pwaSocialMediaTeamListPath(teamId:string) {
  return `/team/${teamId}/social-media`;
}

export function pwaMembersPath() {
  return `/mitglieder`;
}

export function pwaMemberDetailPath(memberId: string | number) {
  return `/mitglied/${memberId}`;
}

export function pwaMemberCreatePath() {
  return `/mitglied/erstellen`;
}

export function pwaMemberEditPath(memberId: string | number) {
  return `/mitglied/${memberId}/bearbeiten`;
}

export function pwaMemberCreateAppAdminPath(query?: Record<string, string>) {
  return path(`/mitglied/erstellen/app-admin`, query);
}

export function pwaMemberCreateExternPath(query?: Record<string, string>) {
  return path(`/mitglied/erstellen/extern`, query);
}

export function pwaEventsPath(query?: Record<string, string>) {
  return path('/termine', query);
}

export function pwaEventAttendancePath(id: string, query?: Record<string, string>) {
  return path(`/termin/${id}/teilnahme`, query);
}

export function pwaEventNewPath(query: Record<string, string>) {
  return path(`/termin/anlegen`, query);
}

export function pwaGameEventNewPath(query: Record<string, string>) {
  return path(`/termin/spiel/anlegen`, query);
}

export function  pwaTeamGameEventAttendancePath(gameId:string, teamId:string){
  return path(`/team/${teamId}/spiel/${gameId}/teilnahme`);
}

export function pwaGameEventEditPath(id: string, query?: Record<string, string>) {
  return path(`/termin/spiel/${id}/bearbeiten`, query);
}

export function pwaEventEditPath(id: string, query?: Record<string, string>) {
  return path(`/termin/${id}/bearbeiten`, query);
}
export function pwaImprintPath() {
  return "/impressum";
}
export function wwwImprintPath() {
  return "/impressum";
}

export function pwaPrivacyPolicyPath() {
  return "/datenschutz";
}
export function wwwPrivacyPolicyPath() {
  return "/datenschutz";
}
export function wwwTermsPath() {
  return "/nutzungsbedingungen";
}
export function wwwClubsPath() {
  return "/vereine";
}


export function pwaWidgetUrl(clubId: string, teamId: string, widgetId: string) {
  return `${pwaHomeUrl(clubId)}/widgets/team/${teamId}/${widgetId}`;
}

export function pwaNotificationsSettingsPath() {
  return `/benachrichtigungen/einstellungen`;
}

export function pwaNotificationsPath() {
  return `/benachrichtigungen`;
}

interface PwaTeamOpponentContestPath {
  teamId: string;
  team: Team;
  contest: Contest;
}

export function pwaTeamOpponentContestPath({
  teamId,
  team,
  contest,
}: PwaTeamOpponentContestPath) {
  if (`${team.Id}` !== `${teamId}`) {
    return `/team/${teamId}/opponent/${team.Id}/contest/${contest.Id}`;
  }

  return `/team/${teamId}`;
}

export function apiMemberCreatePath() {
  return `/api/members/create`;
}

export function apiPollCreatePath() {
  return "/api/poll/create";
}

export function apiPollUpdatePath() {
  return "/api/poll/update";
}

export function apiPollDeletePath() {
  return "/api/poll/delete";
}

export function apiPollListPath(params?:PollListQuery){
  let url = "/api/poll/list";
  if(params) {
    url = url + "?" + objectToQueryString(params);
  }
  return url;
}

export function apiEventCreatePath() {
  return "/api/event/create";
}

export function apiEventUpdatePath() {
  return "/api/event/update";
}

export function apiEventUpdateStatusPath() {
  return "/api/event/update/status";
}

export function apiEventResponseUpsertPath() {
  return "/api/event/create/response";
}

export function apiGameEventCreatePath() {
  return "/api/game-event/create";
}

export function apiGameEventDeletePath() {
  return "/api/game-event/delete";
}

export function apiGameEventUpdatePath() {
  return "/api/game-event/update";
}

export function apiInvitationCreatePath() {
  return "/api/invitation";
}

export function apiInvitationConfirmPath() {
  return "/api/invitation/confirm";
}

export function apiClubSearchPath(searchTerm: string) {
  return `/api/club/search?searchTerm=${searchTerm}`;
}

export function apiTeamSearchPath(searchTerm: string) {
  return `/api/team/search?searchTerm=${searchTerm}`;
}

export function apiEventListPath(params?: EventListQuery){
  let url = "/api/event/list";
  if(params) {
    url = url + "?" + objectToQueryString(params);
  }
  return url;
}

export function apiGetSmaPath(query?: Record<string, string>) {
  return path(`/api/sma`, query);
}

export function apiPushRegistersPath() {
  return "/api/push/register";
}

export function apiPushGetPath() {
  return "/api/push/get";
}

export function apiSetPushChannelsPath() {
  return "/api/push/set-channels";
}

export function apiPushSendPath() {
  return "/api/push/send";
}

export function apiPushNotificationListPath(){
  return "/api/push/list";
}

export function apiCreateTickerPath() {
  return "/api/ticker/create";
}

export function apiLinkTickerPath() {
  return "/api/ticker/link";
}

export function signInPath(callbackUrl?: string) {
  let url = "/auth/signin";
  if(callbackUrl) {
    url += "?" + new URLSearchParams({ callbackUrl });
  }
  return url
}

export function adminHomePath() {
  return "/admin/dashboard?key=fasdgfhasjkhfxgaksdfgkhsadfaszid"
}

export function adminStatisticsPath() {
  return "/admin/stats?key=fasdgfhasjkhfxgaksdfgkhsadfaszid"
}

export function adminInquiriesPath() {
  return "/admin/inqueries?key=fasdgfhasjkhfxgaksdfgkhsadfaszid"
}

export function path(path: string, query?: Record<string, string>) {
  if(query) {
    return `${path}?${objectToQueryString(query)}`;
  }
  return path;
}
